<script>
import AxiosService from "../../axiosServices/AxiosService";
import AvatarCropper from "../AvatarCropper";
export default {
  name: "ImagesManager",
  components: { AvatarCropper },
  mounted() {
    this.getImages();
  },
  data() {
    return {
      images: [],
      currentImage: null,
      currentImageUrl: null,

      loadingUpload: false,

      imageUploaderVisible: false,
      changeAvatarTrigger: false,
    };
  },
  methods: {
    getImages() {
      const service = new AxiosService("getImages");
      service.read().then((res) => {
        if (!res) return;
        this.images = res;
      });
    },
    toggleImageUploader() {
      this.imageUploaderVisible = !this.imageUploaderVisible;
    },
    handleUpload(cropper) {
      const service = new AxiosService("Upload");
      this.loadingUpload = true;
      cropper
        .getCroppedCanvas({
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
          maxWidth: 1000,
          maxHeight: 1000,
        })
        .toBlob(
          async (blob) => {
            const formData = new FormData();

            formData.append("file", blob, "image.jpg");

            service
              .uploadFile(formData)
              .then((res) => {
                if (res) {
                  this.currentImageUrl = this.$driveAddress + res.data;
                }
              })
              .catch((err) => {
                console.log("FILE NOT UPLOADED");
                console.log(err);
              })
              .finally(() => {
                this.loadingUpload = false;
              });
          },
          this.outputMime || this.mimeType,
          this.outputQuality
        );
    },
  },
};
</script>

<template>
  <b-card>
    <b-row>
      <b-col cols="12" class="flex justify-content-between align-items-center">
        <h1>Le tue immagini</h1>
        <b-button @click="toggleImageUploader" variant="primary" icon-left
          ><b-icon icon="plus" class="mr-2"></b-icon>
          Aggiungi una nuova immagine
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="imageUploaderVisible" class="border-dashed my-4">
      <b-col cols="12">
        <div class="py-4">
          <div class="d-flex justify-content-end">
            <b-button variant="outline-primary" @click="toggleImageUploader">
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>
          <div>
            <AvatarCropper
              :trigger="changeAvatarTrigger"
              :labels="{
                submit: 'Salva',
                cancel: 'Annulla',
                main: loadingUpload ? 'Caricamento...' : 'Carica immagine',
              }"
              :upload-handler="handleUpload"
              class="mb-3"
            ></AvatarCropper>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="my-4">
      <b-col cols="8">
        <b-row>
          <b-col cols="12">
            <h4>Immagini</h4>
          </b-col>
          <b-col cols="3" v-for="image in images" :key="image.id"></b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-card>
          <b-row v-if="currentImage">
            <b-col cols="12">
              <h4>Dettagli</h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>
