<script>
import ImagesManager from "../components/ImagesManager/ImagesManager.vue";
export default {
  components: { ImagesManager },
};
</script>

<template>
  <ImagesManager />
</template>
